export default {
  state: {
    inventarSelectedYear: null,
    inventarId: null,
    inventarCoordinates:null,
    inventarTitle: null,
    inventarCurrentPage:null,
    editInventarHistory: null,
    lastEditedInventar:null
  },
  mutations: {
    setEditInventarHistoryUrl(state, data){
      state.editInventarHistory = data
    },
    setSelectedYear(state, data) {
      state.inventarSelectedYear = data;
    },
    setInventarId(state, data) {
      state.inventarId = data;
    },
    setInventarCoordinates(state, data){
      state.inventarCoordinates = data
    },
    setInventarTitle(state, data){
      state.inventarTitle = data;
    },
    setCurrentPage(state, data) {
      state.inventarCurrentPage = data
    },
    setLastEditedInventar(state, data){
      state.lastEditedInventar = data
    }
  },
  actions: {
    updateLastEditedInventar({commit}, data){
      console.log("data id",data);
      commit('setLastEditedInventar', data)
    },
    saveBackToListHistory({commit}, data){
      commit("setEditInventarHistoryUrl",data)
    },
    updateSelectedYear({ commit }, data) {
      commit("setSelectedYear", data);
    },
    updateInventarId({ commit }, data) {
      commit("setInventarId", data);
      localStorage.removeItem('tableTasksCurrentPage');
      localStorage.removeItem('tableTreeCurrentPage');
      localStorage.removeItem('tableBaumControleCurrentPage');
      commit("setEditInventarHistoryUrl",false)

    },
    updateInventarCoordinates({commit},data){
      commit("setInventarCoordinates",data);
    },
    updateInventarCurrentPage({commit},data){
      commit("setCurrentPage",data)
    },
    updateInventarTitle({commit},data){
      commit("setInventarTitle",data);
      commit("setEditInventarHistoryUrl",false)
    },
  },
  getters: {
    inventarHaveBeenSaved: state => state.editInventarHistory,
    inventarSelectedYear: state => state.inventarSelectedYear,
    inventarId: state => state.inventarId,
    inventarTitle: state =>state.inventarTitle,
    inventarCoordinates: state => state.inventarCoordinates,
    inventarCurrentPage: state => state.inventarCurrentPage,
    editedInventar: sate => sate.lastEditedInventar
  }
};
