import axios from 'axios';

const state = {
    year:null
};

const mutations = {
  SET_YEAR(state, year) {
    state.year = year;
  }
};

const actions = {
  async setTaskListYear({ commit }, year) {
    commit('SET_YEAR', year);
  }
};

const getters = {
getSelectedTaskYear: (state) => state.year
};

export default {
  state,
  mutations,
  actions,
  getters
};
