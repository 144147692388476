const state ={
    swissCities:[
        {
          "city": "Zürich", 
          "lat": "47.3744", 
          "lng": "8.5411", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "admin", 
          "population": "447082", 
          "population_proper": "447082"
        }, 
        {
          "city": "Geneva", 
          "lat": "46.2017", 
          "lng": "6.1469", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "admin", 
          "population": "203840", 
          "population_proper": "203840"
        }, 
        {
          "city": "Basel", 
          "lat": "47.5547", 
          "lng": "7.5906", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Stadt", 
          "capital": "admin", 
          "population": "173552", 
          "population_proper": "173552"
        }, 
        {
          "city": "Lausanne", 
          "lat": "46.5198", 
          "lng": "6.6335", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "admin", 
          "population": "141418", 
          "population_proper": "141418"
        }, 
        {
          "city": "Bern", 
          "lat": "46.9481", 
          "lng": "7.4475", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Bern", 
          "capital": "primary", 
          "population": "134506", 
          "population_proper": "134506"
        }, 
        {
          "city": "Winterthur", 
          "lat": "47.4989", 
          "lng": "8.7286", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "minor", 
          "population": "109775", 
          "population_proper": "109775"
        }, 
        {
          "city": "Lucerne", 
          "lat": "47.0500", 
          "lng": "8.3000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Luzern", 
          "capital": "admin", 
          "population": "81691", 
          "population_proper": "81691"
        }, 
        {
          "city": "Sankt Gallen", 
          "lat": "47.4242", 
          "lng": "9.3708", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Sankt Gallen", 
          "capital": "admin", 
          "population": "75833", 
          "population_proper": "75833"
        }, 
        {
          "city": "Biel/Bienne", 
          "lat": "47.1333", 
          "lng": "7.2500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Bern", 
          "capital": "", 
          "population": "55159", 
          "population_proper": "55159"
        }, 
        {
          "city": "Bellinzona", 
          "lat": "46.1954", 
          "lng": "9.0297", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Ticino", 
          "capital": "admin", 
          "population": "43785", 
          "population_proper": "43785"
        }, 
        {
          "city": "Thun", 
          "lat": "46.7667", 
          "lng": "7.6333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Bern", 
          "capital": "minor", 
          "population": "43734", 
          "population_proper": "43734"
        }, 
        {
          "city": "Fribourg", 
          "lat": "46.8000", 
          "lng": "7.1500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Fribourg", 
          "capital": "admin", 
          "population": "38365", 
          "population_proper": "38365"
        }, 
        {
          "city": "Uster", 
          "lat": "47.3500", 
          "lng": "8.7167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "minor", 
          "population": "36791", 
          "population_proper": "36791"
        }, 
        {
          "city": "Schaffhausen", 
          "lat": "47.7000", 
          "lng": "8.6333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Schaffhausen", 
          "capital": "admin", 
          "population": "36587", 
          "population_proper": "36587"
        }, 
        {
          "city": "Chur", 
          "lat": "46.8500", 
          "lng": "9.5333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Graubünden", 
          "capital": "admin", 
          "population": "35038", 
          "population_proper": "35038"
        }, 
        {
          "city": "Vernier", 
          "lat": "46.2000", 
          "lng": "6.1000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "", 
          "population": "34774", 
          "population_proper": "34774"
        }, 
        {
          "city": "Sion", 
          "lat": "46.2311", 
          "lng": "7.3594", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Valais", 
          "capital": "admin", 
          "population": "34708", 
          "population_proper": "34708"
        }, 
        {
          "city": "Neuchâtel", 
          "lat": "47.0000", 
          "lng": "6.9333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Neuchâtel", 
          "capital": "admin", 
          "population": "33475", 
          "population_proper": "33475"
        }, 
        {
          "city": "Zug", 
          "lat": "47.1681", 
          "lng": "8.5169", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zug", 
          "capital": "admin", 
          "population": "30542", 
          "population_proper": "30542"
        }, 
        {
          "city": "Yverdon-les-Bains", 
          "lat": "46.7785", 
          "lng": "6.6408", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "minor", 
          "population": "30157", 
          "population_proper": "30157"
        }, 
        {
          "city": "Emmen", 
          "lat": "47.0833", 
          "lng": "8.3000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Luzern", 
          "capital": "", 
          "population": "29292", 
          "population_proper": "29292"
        }, 
        {
          "city": "Dübendorf", 
          "lat": "47.4167", 
          "lng": "8.6167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "28002", 
          "population_proper": "28002"
        }, 
        {
          "city": "Rapperswil-Jona", 
          "lat": "47.2167", 
          "lng": "8.8167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Sankt Gallen", 
          "capital": "minor", 
          "population": "26995", 
          "population_proper": "26995"
        }, 
        {
          "city": "Dietikon", 
          "lat": "47.4000", 
          "lng": "8.4000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "26936", 
          "population_proper": "26936"
        }, 
        {
          "city": "Wetzikon", 
          "lat": "47.3167", 
          "lng": "8.8000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "24452", 
          "population_proper": "24452"
        }, 
        {
          "city": "Meyrin", 
          "lat": "46.2167", 
          "lng": "6.0667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "", 
          "population": "24144", 
          "population_proper": "24144"
        }, 
        {
          "city": "Carouge", 
          "lat": "46.1833", 
          "lng": "6.1333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "", 
          "population": "22336", 
          "population_proper": "22336"
        }, 
        {
          "city": "Frauenfeld", 
          "lat": "47.5500", 
          "lng": "8.9000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Thurgau", 
          "capital": "admin", 
          "population": "22313", 
          "population_proper": "22313"
        }, 
        {
          "city": "Kreuzlingen", 
          "lat": "47.6333", 
          "lng": "9.1667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Thurgau", 
          "capital": "minor", 
          "population": "21801", 
          "population_proper": "21801"
        }, 
        {
          "city": "Wädenswil", 
          "lat": "47.2333", 
          "lng": "8.6667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "21716", 
          "population_proper": "21716"
        }, 
        {
          "city": "Aarau", 
          "lat": "47.4000", 
          "lng": "8.0500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "admin", 
          "population": "21503", 
          "population_proper": "21503"
        }, 
        {
          "city": "Riehen", 
          "lat": "47.5833", 
          "lng": "7.6333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Stadt", 
          "capital": "", 
          "population": "21448", 
          "population_proper": "21448"
        }, 
        {
          "city": "Allschwil", 
          "lat": "47.5500", 
          "lng": "7.5333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Landschaft", 
          "capital": "", 
          "population": "21150", 
          "population_proper": "21150"
        }, 
        {
          "city": "Renens", 
          "lat": "46.5333", 
          "lng": "6.5833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "minor", 
          "population": "20927", 
          "population_proper": "20927"
        }, 
        {
          "city": "Wettingen", 
          "lat": "47.4667", 
          "lng": "8.3333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "20843", 
          "population_proper": "20843"
        }, 
        {
          "city": "Nyon", 
          "lat": "46.3833", 
          "lng": "6.2333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "20272", 
          "population_proper": "20272"
        }, 
        {
          "city": "Bülach", 
          "lat": "47.5167", 
          "lng": "8.5333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "20046", 
          "population_proper": "20046"
        }, 
        {
          "city": "Vevey", 
          "lat": "46.4667", 
          "lng": "6.8500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "19891", 
          "population_proper": "19891"
        }, 
        {
          "city": "Opfikon", 
          "lat": "47.4333", 
          "lng": "8.5667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "19864", 
          "population_proper": "19864"
        }, 
        {
          "city": "Reinach", 
          "lat": "47.4833", 
          "lng": "7.5833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Landschaft", 
          "capital": "", 
          "population": "19144", 
          "population_proper": "19144"
        }, 
        {
          "city": "Baden", 
          "lat": "47.4667", 
          "lng": "8.3000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "19122", 
          "population_proper": "19122"
        }, 
        {
          "city": "Onex", 
          "lat": "46.1833", 
          "lng": "6.1000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "", 
          "population": "18977", 
          "population_proper": "18977"
        }, 
        {
          "city": "Schlieren", 
          "lat": "47.4000", 
          "lng": "8.4500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "18731", 
          "population_proper": "18731"
        }, 
        {
          "city": "Adliswil", 
          "lat": "47.3167", 
          "lng": "8.5333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "18731", 
          "population_proper": "18731"
        }, 
        {
          "city": "Volketswil", 
          "lat": "47.3833", 
          "lng": "8.7000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "18615", 
          "population_proper": "18615"
        }, 
        {
          "city": "Thalwil", 
          "lat": "47.2833", 
          "lng": "8.5667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "18525", 
          "population_proper": "18525"
        }, 
        {
          "city": "Olten", 
          "lat": "47.3500", 
          "lng": "7.9000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Solothurn", 
          "capital": "", 
          "population": "18362", 
          "population_proper": "18362"
        }, 
        {
          "city": "Pully", 
          "lat": "46.5167", 
          "lng": "6.6667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "18313", 
          "population_proper": "18313"
        }, 
        {
          "city": "Regensdorf", 
          "lat": "47.4333", 
          "lng": "8.4667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "18253", 
          "population_proper": "18253"
        }, 
        {
          "city": "Ostermundigen", 
          "lat": "46.9550", 
          "lng": "7.4894", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Bern", 
          "capital": "", 
          "population": "17751", 
          "population_proper": "17751"
        }, 
        {
          "city": "Littau", 
          "lat": "47.0500", 
          "lng": "8.2667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Luzern", 
          "capital": "", 
          "population": "17224", 
          "population_proper": "17224"
        }, 
        {
          "city": "Solothurn", 
          "lat": "47.2167", 
          "lng": "7.5333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Solothurn", 
          "capital": "admin", 
          "population": "16777", 
          "population_proper": "16777"
        }, 
        {
          "city": "Pratteln", 
          "lat": "47.5167", 
          "lng": "7.7000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Landschaft", 
          "capital": "", 
          "population": "16621", 
          "population_proper": "16621"
        }, 
        {
          "city": "Freienbach", 
          "lat": "47.2000", 
          "lng": "8.7500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Schwyz", 
          "capital": "", 
          "population": "16391", 
          "population_proper": "16391"
        }, 
        {
          "city": "Wallisellen", 
          "lat": "47.4167", 
          "lng": "8.6000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "16315", 
          "population_proper": "16315"
        }, 
        {
          "city": "Wohlen", 
          "lat": "47.3500", 
          "lng": "8.2833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "16078", 
          "population_proper": "16078"
        }, 
        {
          "city": "Herisau", 
          "lat": "47.3833", 
          "lng": "9.2667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Appenzell Ausserrhoden", 
          "capital": "admin", 
          "population": "15744", 
          "population_proper": "15744"
        }, 
        {
          "city": "Steffisburg", 
          "lat": "46.7667", 
          "lng": "7.6333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Bern", 
          "capital": "", 
          "population": "15709", 
          "population_proper": "15709"
        }, 
        {
          "city": "Morges", 
          "lat": "46.5167", 
          "lng": "6.5000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "15705", 
          "population_proper": "15705"
        }, 
        {
          "city": "Binningen", 
          "lat": "47.5333", 
          "lng": "7.5667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Landschaft", 
          "capital": "", 
          "population": "15675", 
          "population_proper": "15675"
        }, 
        {
          "city": "Schwyz", 
          "lat": "47.0167", 
          "lng": "8.6500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Schwyz", 
          "capital": "admin", 
          "population": "15181", 
          "population_proper": "15181"
        }, 
        {
          "city": "Arbon", 
          "lat": "47.5167", 
          "lng": "9.4333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Thurgau", 
          "capital": "", 
          "population": "14537", 
          "population_proper": "14537"
        }, 
        {
          "city": "Liestal", 
          "lat": "47.4667", 
          "lng": "7.7333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Landschaft", 
          "capital": "admin", 
          "population": "14390", 
          "population_proper": "14390"
        }, 
        {
          "city": "Stäfa", 
          "lat": "47.2400", 
          "lng": "8.7300", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "14389", 
          "population_proper": "14389"
        }, 
        {
          "city": "Küsnacht", 
          "lat": "47.3167", 
          "lng": "8.5833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "14387", 
          "population_proper": "14387"
        }, 
        {
          "city": "Thônex", 
          "lat": "46.1833", 
          "lng": "6.1833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "", 
          "population": "14122", 
          "population_proper": "14122"
        }, 
        {
          "city": "Meilen", 
          "lat": "47.2667", 
          "lng": "8.6333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "13999", 
          "population_proper": "13999"
        }, 
        {
          "city": "Versoix", 
          "lat": "46.2833", 
          "lng": "6.1667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "", 
          "population": "13422", 
          "population_proper": "13422"
        }, 
        {
          "city": "Richterswil", 
          "lat": "47.2167", 
          "lng": "8.7000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "13407", 
          "population_proper": "13407"
        }, 
        {
          "city": "Zollikon", 
          "lat": "47.3333", 
          "lng": "8.5833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "13367", 
          "population_proper": "13367"
        }, 
        {
          "city": "Gland", 
          "lat": "46.4200", 
          "lng": "6.2700", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "13106", 
          "population_proper": "13106"
        }, 
        {
          "city": "Muri", 
          "lat": "46.9333", 
          "lng": "7.4833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Bern", 
          "capital": "", 
          "population": "13054", 
          "population_proper": "13054"
        }, 
        {
          "city": "Ecublens", 
          "lat": "46.5333", 
          "lng": "6.5667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "12854", 
          "population_proper": "12854"
        }, 
        {
          "city": "Delémont", 
          "lat": "47.3667", 
          "lng": "7.3500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Jura", 
          "capital": "admin", 
          "population": "12682", 
          "population_proper": "12682"
        }, 
        {
          "city": "Prilly", 
          "lat": "46.5333", 
          "lng": "6.6000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "12399", 
          "population_proper": "12399"
        }, 
        {
          "city": "Chêne-Bougeries", 
          "lat": "46.1833", 
          "lng": "6.1833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "", 
          "population": "12215", 
          "population_proper": "12215"
        }, 
        {
          "city": "Rüti", 
          "lat": "47.2667", 
          "lng": "8.8500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "12170", 
          "population_proper": "12170"
        }, 
        {
          "city": "Le Grand-Saconnex", 
          "lat": "46.2333", 
          "lng": "6.1167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "", 
          "population": "12162", 
          "population_proper": "12162"
        }, 
        {
          "city": "Münchenstein", 
          "lat": "47.5167", 
          "lng": "7.6167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Landschaft", 
          "capital": "", 
          "population": "12096", 
          "population_proper": "12096"
        }, 
        {
          "city": "Villars-sur-Glâne", 
          "lat": "46.7833", 
          "lng": "7.1167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Fribourg", 
          "capital": "", 
          "population": "12092", 
          "population_proper": "12092"
        }, 
        {
          "city": "La Tour-de-Peilz", 
          "lat": "46.4500", 
          "lng": "6.8667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "11829", 
          "population_proper": "11829"
        }, 
        {
          "city": "Spreitenbach", 
          "lat": "47.4167", 
          "lng": "8.3667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "11788", 
          "population_proper": "11788"
        }, 
        {
          "city": "Veyrier", 
          "lat": "46.1667", 
          "lng": "6.1833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "", 
          "population": "11609", 
          "population_proper": "11609"
        }, 
        {
          "city": "Bassersdorf", 
          "lat": "47.4500", 
          "lng": "8.6333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "11593", 
          "population_proper": "11593"
        }, 
        {
          "city": "Männedorf", 
          "lat": "47.2500", 
          "lng": "8.6833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "11242", 
          "population_proper": "11242"
        }, 
        {
          "city": "Romanshorn", 
          "lat": "47.5667", 
          "lng": "9.3833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Thurgau", 
          "capital": "", 
          "population": "11167", 
          "population_proper": "11167"
        }, 
        {
          "city": "Oberwil", 
          "lat": "47.5167", 
          "lng": "7.5500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Landschaft", 
          "capital": "", 
          "population": "11136", 
          "population_proper": "11136"
        }, 
        {
          "city": "Brugg", 
          "lat": "47.4864", 
          "lng": "8.2067", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "11129", 
          "population_proper": "11129"
        }, 
        {
          "city": "Plan-les-Ouates", 
          "lat": "46.1667", 
          "lng": "6.1167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "", 
          "population": "10656", 
          "population_proper": "10656"
        }, 
        {
          "city": "Neuhausen am Rheinfall", 
          "lat": "47.6833", 
          "lng": "8.6167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Schaffhausen", 
          "capital": "", 
          "population": "10510", 
          "population_proper": "10510"
        }, 
        {
          "city": "Sarnen", 
          "lat": "46.8961", 
          "lng": "8.2456", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Obwalden", 
          "capital": "admin", 
          "population": "10368", 
          "population_proper": "10368"
        }, 
        {
          "city": "Aesch", 
          "lat": "47.4667", 
          "lng": "7.5833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Landschaft", 
          "capital": "", 
          "population": "10352", 
          "population_proper": "10352"
        }, 
        {
          "city": "Birsfelden", 
          "lat": "47.5533", 
          "lng": "7.6233", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Stadt", 
          "capital": "", 
          "population": "10299", 
          "population_proper": "10299"
        }, 
        {
          "city": "Lutry", 
          "lat": "46.5000", 
          "lng": "6.6833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "10289", 
          "population_proper": "10289"
        }, 
        {
          "city": "Sursee", 
          "lat": "47.1667", 
          "lng": "8.1167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Luzern", 
          "capital": "minor", 
          "population": "9900", 
          "population_proper": "9900"
        }, 
        {
          "city": "Therwil", 
          "lat": "47.5000", 
          "lng": "7.5500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Landschaft", 
          "capital": "", 
          "population": "9867", 
          "population_proper": "9867"
        }, 
        {
          "city": "Urdorf", 
          "lat": "47.3833", 
          "lng": "8.4333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "9768", 
          "population_proper": "9768"
        }, 
        {
          "city": "Widnau", 
          "lat": "47.4000", 
          "lng": "9.6333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Sankt Gallen", 
          "capital": "", 
          "population": "9616", 
          "population_proper": "9616"
        }, 
        {
          "city": "Epalinges", 
          "lat": "46.5500", 
          "lng": "6.6667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "9611", 
          "population_proper": "9611"
        }, 
        {
          "city": "Rorschach", 
          "lat": "47.4667", 
          "lng": "9.5000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Sankt Gallen", 
          "capital": "minor", 
          "population": "9439", 
          "population_proper": "9439"
        }, 
        {
          "city": "Altdorf", 
          "lat": "46.8667", 
          "lng": "8.6333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Uri", 
          "capital": "admin", 
          "population": "9401", 
          "population_proper": "9401"
        }, 
        {
          "city": "Goldach", 
          "lat": "47.4833", 
          "lng": "9.4667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Sankt Gallen", 
          "capital": "", 
          "population": "9269", 
          "population_proper": "9269"
        }, 
        {
          "city": "Arlesheim", 
          "lat": "47.4833", 
          "lng": "7.6167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Landschaft", 
          "capital": "", 
          "population": "9202", 
          "population_proper": "9202"
        }, 
        {
          "city": "Zuchwil", 
          "lat": "47.2000", 
          "lng": "7.5667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Solothurn", 
          "capital": "", 
          "population": "8875", 
          "population_proper": "8875"
        }, 
        {
          "city": "Kilchberg", 
          "lat": "47.3167", 
          "lng": "8.5500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "8851", 
          "population_proper": "8851"
        }, 
        {
          "city": "Neuenhof", 
          "lat": "47.4500", 
          "lng": "8.3167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "8824", 
          "population_proper": "8824"
        }, 
        {
          "city": "Lachen", 
          "lat": "47.1833", 
          "lng": "8.8500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Schwyz", 
          "capital": "minor", 
          "population": "8754", 
          "population_proper": "8754"
        }, 
        {
          "city": "Fällanden", 
          "lat": "47.3667", 
          "lng": "8.6333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "8575", 
          "population_proper": "8575"
        }, 
        {
          "city": "Stans", 
          "lat": "46.9500", 
          "lng": "8.3500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Nidwalden", 
          "capital": "admin", 
          "population": "8393", 
          "population_proper": "8393"
        }, 
        {
          "city": "Oberentfelden", 
          "lat": "47.3667", 
          "lng": "8.0500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "8360", 
          "population_proper": "8360"
        }, 
        {
          "city": "Aarburg", 
          "lat": "47.3167", 
          "lng": "7.9000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "8068", 
          "population_proper": "8068"
        }, 
        {
          "city": "Chiasso", 
          "lat": "45.8333", 
          "lng": "9.0333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Ticino", 
          "capital": "", 
          "population": "7975", 
          "population_proper": "7975"
        }, 
        {
          "city": "Buchs", 
          "lat": "47.3833", 
          "lng": "8.0833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "7966", 
          "population_proper": "7966"
        }, 
        {
          "city": "Crissier", 
          "lat": "46.5500", 
          "lng": "6.5833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "7930", 
          "population_proper": "7930"
        }, 
        {
          "city": "Au", 
          "lat": "47.4333", 
          "lng": "9.6333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Sankt Gallen", 
          "capital": "", 
          "population": "7788", 
          "population_proper": "7788"
        }, 
        {
          "city": "Dietlikon", 
          "lat": "47.4167", 
          "lng": "8.6167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "7773", 
          "population_proper": "7773"
        }, 
        {
          "city": "Chavannes-près-Renens", 
          "lat": "46.5333", 
          "lng": "6.5833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "7724", 
          "population_proper": "7724"
        }, 
        {
          "city": "Windisch", 
          "lat": "47.4833", 
          "lng": "8.2167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "7654", 
          "population_proper": "7654"
        }, 
        {
          "city": "Minusio", 
          "lat": "46.1833", 
          "lng": "8.8167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Ticino", 
          "capital": "", 
          "population": "7303", 
          "population_proper": "7303"
        }, 
        {
          "city": "Heimberg", 
          "lat": "46.7833", 
          "lng": "7.6000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Bern", 
          "capital": "", 
          "population": "6827", 
          "population_proper": "6827"
        }, 
        {
          "city": "Nidau", 
          "lat": "47.1333", 
          "lng": "7.2500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Bern", 
          "capital": "", 
          "population": "6815", 
          "population_proper": "6815"
        }, 
        {
          "city": "Dornach", 
          "lat": "47.4833", 
          "lng": "7.6167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Solothurn", 
          "capital": "", 
          "population": "6778", 
          "population_proper": "6778"
        }, 
        {
          "city": "Bottmingen", 
          "lat": "47.5167", 
          "lng": "7.5667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Landschaft", 
          "capital": "", 
          "population": "6691", 
          "population_proper": "6691"
        }, 
        {
          "city": "Oberengstringen", 
          "lat": "47.4167", 
          "lng": "8.4667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "6569", 
          "population_proper": "6569"
        }, 
        {
          "city": "Frenkendorf", 
          "lat": "47.5000", 
          "lng": "7.7167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Basel-Landschaft", 
          "capital": "", 
          "population": "6435", 
          "population_proper": "6435"
        }, 
        {
          "city": "Rolle", 
          "lat": "46.4500", 
          "lng": "6.3333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "6259", 
          "population_proper": "6259"
        }, 
        {
          "city": "Buchrain", 
          "lat": "47.1000", 
          "lng": "8.3500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Luzern", 
          "capital": "", 
          "population": "6235", 
          "population_proper": "6235"
        }, 
        {
          "city": "Massagno", 
          "lat": "46.0167", 
          "lng": "8.9500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Ticino", 
          "capital": "", 
          "population": "6227", 
          "population_proper": "6227"
        }, 
        {
          "city": "Uetikon am See", 
          "lat": "47.2667", 
          "lng": "8.6833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "6145", 
          "population_proper": "6145"
        }, 
        {
          "city": "Glarus", 
          "lat": "47.0333", 
          "lng": "9.0667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Glarus", 
          "capital": "admin", 
          "population": "5996", 
          "population_proper": "5996"
        }, 
        {
          "city": "Rüschlikon", 
          "lat": "47.2833", 
          "lng": "8.5500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "5830", 
          "population_proper": "5830"
        }, 
        {
          "city": "Peseux", 
          "lat": "46.9833", 
          "lng": "6.8833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Neuchâtel", 
          "capital": "", 
          "population": "5809", 
          "population_proper": "5809"
        }, 
        {
          "city": "Appenzell", 
          "lat": "47.3333", 
          "lng": "9.4167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Appenzell Innerrhoden", 
          "capital": "admin", 
          "population": "5750", 
          "population_proper": "5750"
        }, 
        {
          "city": "Interlaken", 
          "lat": "46.6833", 
          "lng": "7.8500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Bern", 
          "capital": "minor", 
          "population": "5610", 
          "population_proper": "5610"
        }, 
        {
          "city": "Cologny", 
          "lat": "46.2156", 
          "lng": "6.1808", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "", 
          "population": "5570", 
          "population_proper": "5570"
        }, 
        {
          "city": "Erlenbach", 
          "lat": "47.3000", 
          "lng": "8.5833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "5567", 
          "population_proper": "5567"
        }, 
        {
          "city": "Colombier", 
          "lat": "46.9667", 
          "lng": "6.8667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Neuchâtel", 
          "capital": "", 
          "population": "5547", 
          "population_proper": "5547"
        }, 
        {
          "city": "Courtepin", 
          "lat": "46.8667", 
          "lng": "7.1167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Fribourg", 
          "capital": "", 
          "population": "5451", 
          "population_proper": "5451"
        }, 
        {
          "city": "Greifensee", 
          "lat": "47.3667", 
          "lng": "8.6833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "5345", 
          "population_proper": "5345"
        }, 
        {
          "city": "Préverenges", 
          "lat": "46.5167", 
          "lng": "6.5333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "5287", 
          "population_proper": "5287"
        }, 
        {
          "city": "Gerlafingen", 
          "lat": "47.1667", 
          "lng": "7.5667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Solothurn", 
          "capital": "", 
          "population": "5202", 
          "population_proper": "5202"
        }, 
        {
          "city": "Schwerzenbach", 
          "lat": "47.3833", 
          "lng": "8.6667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "5088", 
          "population_proper": "5088"
        }, 
        {
          "city": "Oberrieden", 
          "lat": "47.2833", 
          "lng": "8.5833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "5083", 
          "population_proper": "5083"
        }, 
        {
          "city": "Geroldswil", 
          "lat": "47.4167", 
          "lng": "8.4167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "4918", 
          "population_proper": "4918"
        }, 
        {
          "city": "Niederglatt", 
          "lat": "47.5167", 
          "lng": "8.5000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "4917", 
          "population_proper": "4917"
        }, 
        {
          "city": "Schönenwerd", 
          "lat": "47.3667", 
          "lng": "8.0000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Solothurn", 
          "capital": "", 
          "population": "4910", 
          "population_proper": "4910"
        }, 
        {
          "city": "Niederlenz", 
          "lat": "47.4000", 
          "lng": "8.1833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "4776", 
          "population_proper": "4776"
        }, 
        {
          "city": "Cortaillod", 
          "lat": "46.9500", 
          "lng": "6.8500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Neuchâtel", 
          "capital": "", 
          "population": "4729", 
          "population_proper": "4729"
        }, 
        {
          "city": "Saint-Sulpice", 
          "lat": "46.5167", 
          "lng": "6.5667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "4668", 
          "population_proper": "4668"
        }, 
        {
          "city": "Confignon", 
          "lat": "46.1667", 
          "lng": "6.0167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Genève", 
          "capital": "", 
          "population": "4627", 
          "population_proper": "4627"
        }, 
        {
          "city": "Morbio Inferiore", 
          "lat": "45.8500", 
          "lng": "9.0167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Ticino", 
          "capital": "", 
          "population": "4575", 
          "population_proper": "4575"
        }, 
        {
          "city": "Caslano", 
          "lat": "45.9667", 
          "lng": "8.8833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Ticino", 
          "capital": "", 
          "population": "4356", 
          "population_proper": "4356"
        }, 
        {
          "city": "Gross Höchstetten", 
          "lat": "46.9000", 
          "lng": "7.6333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Bern", 
          "capital": "", 
          "population": "4165", 
          "population_proper": "4165"
        }, 
        {
          "city": "Unterentfelden", 
          "lat": "47.3667", 
          "lng": "8.0333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "4155", 
          "population_proper": "4155"
        }, 
        {
          "city": "Paradiso", 
          "lat": "45.9833", 
          "lng": "8.9500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Ticino", 
          "capital": "", 
          "population": "4144", 
          "population_proper": "4144"
        }, 
        {
          "city": "Niederrohrdorf", 
          "lat": "47.4167", 
          "lng": "8.3167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "4105", 
          "population_proper": "4105"
        }, 
        {
          "city": "Hilterfingen", 
          "lat": "46.7333", 
          "lng": "7.6500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Bern", 
          "capital": "", 
          "population": "4044", 
          "population_proper": "4044"
        }, 
        {
          "city": "Hunzenschwil", 
          "lat": "47.3833", 
          "lng": "8.1333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "4014", 
          "population_proper": "4014"
        }, 
        {
          "city": "Unterengstringen", 
          "lat": "47.4167", 
          "lng": "8.4500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "3880", 
          "population_proper": "3880"
        }, 
        {
          "city": "Langendorf", 
          "lat": "47.2167", 
          "lng": "7.5167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Solothurn", 
          "capital": "", 
          "population": "3781", 
          "population_proper": "3781"
        }, 
        {
          "city": "Belmont-sur-Lausanne", 
          "lat": "46.5167", 
          "lng": "6.6833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "3721", 
          "population_proper": "3721"
        }, 
        {
          "city": "Widen", 
          "lat": "47.3667", 
          "lng": "8.3667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "3698", 
          "population_proper": "3698"
        }, 
        {
          "city": "Port", 
          "lat": "47.1167", 
          "lng": "7.2500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Bern", 
          "capital": "", 
          "population": "3695", 
          "population_proper": "3695"
        }, 
        {
          "city": "Ennetbaden", 
          "lat": "47.4833", 
          "lng": "8.3167", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "3693", 
          "population_proper": "3693"
        }, 
        {
          "city": "Feuerthalen", 
          "lat": "47.6833", 
          "lng": "8.6500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Zürich", 
          "capital": "", 
          "population": "3636", 
          "population_proper": "3636"
        }, 
        {
          "city": "Rheineck", 
          "lat": "47.4667", 
          "lng": "9.5833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Sankt Gallen", 
          "capital": "", 
          "population": "3419", 
          "population_proper": "3419"
        }, 
        {
          "city": "Vacallo", 
          "lat": "45.8500", 
          "lng": "9.0333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Ticino", 
          "capital": "", 
          "population": "3398", 
          "population_proper": "3398"
        }, 
        {
          "city": "Corsier-sur-Vevey", 
          "lat": "46.4667", 
          "lng": "6.8500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "3390", 
          "population_proper": "3390"
        }, 
        {
          "city": "Balerna", 
          "lat": "45.8500", 
          "lng": "9.0000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Ticino", 
          "capital": "", 
          "population": "3269", 
          "population_proper": "3269"
        }, 
        {
          "city": "Givisiez", 
          "lat": "46.8167", 
          "lng": "7.1333", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Fribourg", 
          "capital": "", 
          "population": "3166", 
          "population_proper": "3166"
        }, 
        {
          "city": "Coppet", 
          "lat": "46.3167", 
          "lng": "6.1917", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Vaud", 
          "capital": "", 
          "population": "3152", 
          "population_proper": "3152"
        }, 
        {
          "city": "Turgi", 
          "lat": "47.5000", 
          "lng": "8.2500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Aargau", 
          "capital": "", 
          "population": "3014", 
          "population_proper": "3014"
        }, 
        {
          "city": "Coldrerio", 
          "lat": "45.8500", 
          "lng": "8.9833", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Ticino", 
          "capital": "", 
          "population": "2939", 
          "population_proper": "2939"
        }, 
        {
          "city": "Rickenbach bei Wil", 
          "lat": "47.4500", 
          "lng": "9.0500", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Sankt Gallen", 
          "capital": "", 
          "population": "2774", 
          "population_proper": "2774"
        }, 
        {
          "city": "Horn", 
          "lat": "47.5000", 
          "lng": "9.4667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Sankt Gallen", 
          "capital": "", 
          "population": "2752", 
          "population_proper": "2752"
        }, 
        {
          "city": "Muralto", 
          "lat": "46.1667", 
          "lng": "8.8000", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Ticino", 
          "capital": "", 
          "population": "2683", 
          "population_proper": "2683"
        }, 
        {
          "city": "Hauterive", 
          "lat": "47.0167", 
          "lng": "6.9667", 
          "country": "Switzerland", 
          "iso2": "CH", 
          "admin_name": "Neuchâtel", 
          "capital": "", 
          "population": "2623", 
          "population_proper": "2623"
        }
      ]     
}

const getters = {
    switzerlandCities: (state) => {
        return state.swissCities.map(item => ({
            city: item.city,
            coordinates: [
                [parseFloat(item.lat), parseFloat(item.lng)]
            ]
        }));
    }
};

export default{
    state,
    getters
}